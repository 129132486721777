<template>
  <main
    class="
      bg-transparent
      xl:px-6
      lg:px-6
      md:px-8
      px-3
      pb-32
      text-legend-gray
      dark:text-border-color
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main>
        <section class="capitalize flex items-center mb-6">
          <home-icon
            size="1.3x"
            class="custom-class text-primary-color cursor-pointer"
            @click="$router.push({ name: 'Purchases' })"
          ></home-icon
          ><span class="mx-2">/</span>
          <span class="capitalize">
            {{
              categoryId ? $route.params.purchaseCategory : 'All stores'
            }}</span
          >
        </section>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
        <h2
          class="
            font-bold
            text-lg text-legend-black
            dark:text-border-color
            mt-12
            mb-6
          "
        >
          {{ categoryId ? $route.params.purchaseCategory : 'All stores' }}
        </h2>
        <section
          v-if="merchants && !categoryId"
          class="
            w-80
            flex
            items-center
            border border-border-color
            rounded-lg
            p-2
          "
        >
          <img
            src="@/assets/images/search.svg"
            alt="search products"
            class="flex-shrink-0"
          />
          <input
            v-debounce:1000="search"
            v-model="searchTerm"
            type="text"
            class="w-full focus:outline-none bg-transparent px-2"
            placeholder="Search"
          />
          <x-icon
            v-if="!categoryId && searchTerm.length"
            size="1.5x"
            class="custom-class cursor-pointer"
            @click="fetchMerchantByCategory"
          ></x-icon>
        </section>
      </main>
    </section>
    <LoadingSpinner v-if="loading && !merchants" />
    <p
      v-if="!loading && merchants && !merchants.length"
      class="font-semibold text-center py-12"
    >
      Merchant coming soon!
    </p>
    <ul
      v-if="!loading && merchants && merchants.length"
      class="
        xl:w-4/5
        lg:w-full
        md:w-full
        w-full
        grid
        xl:grid-cols-4
        lg:grid-cols-3
        md:grid-cols-3
        grid-cols-2
        xl:gap-6
        lg:gap-6
        md:gap-6
        gap-3
        mt-12
      "
    >
      <li
        v-for="merchant in merchants"
        :key="merchant.id"
        class="
          p-3.5
          bg-white
          dark:bg-dark-mode-primary
          rounded
          pb-8
          cursor-pointer
        "
        @click="productView(merchant)"
      >
        <div class="w-20 h-20 rounded-full bg-gray-200">
          <img
            v-if="merchant.image"
            :src="`${url}/${merchant.image}`"
            :alt="merchant.name"
            class="w-full h-full object-cover rounded-full"
          />
          <!-- <img
            v-else
            :src="getImgUrl()"
            :alt="merchant.name"
            class="w-full h-full object-cover rounded-full"
          /> -->
        </div>
        <h1 class="font-bold mt-2 mb-3">
          {{ merchant.name }}
        </h1>
        <p class="text-sm">{{ merchant.location }}</p>
      </li>
    </ul>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import errorModule from '@/util/error.handle'
import { HomeIcon, XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'Purchases',
  components: {
    DashboardCard,
    HomeIcon,
    XIcon,
    // PaginationComponent,
  },
  mounted() {
    this.fetchMerchantByCategory()
  },
  data() {
    return {
      merchants: null,
      notification: null,
      searchTerm: '',
      showWalletBalance: false,
      showRewardPoints: false,
      fundingWallet: false,
      loading: false,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'categoryId']),
  },
  methods: {
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    productView(merchant) {
      this.$store.commit('SET_CURRENT_MERCHANT', merchant)

      this.$nextTick(() => {
        this.$router.push({
          name: 'PurchaseCategoryMerchant',
          params: {
            purchaseCategory: this.$route.params.purchaseCategory,
            merchant: merchant.name,
          },
        })
      })
    },
    async search() {
      try {
        const { status, data } = await this.axios.get(
          `account/products/branches?search=${this.searchTerm}`
        )

        if (status === 200) {
          this.merchants = data.data.branches
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchMerchantByCategory() {
      try {
        this.searchTerm = ''
        this.loading = true

        let url = this.categoryId
          ? `account/products/branches?category=${this.categoryId}`
          : 'account/products/branches'

        const { status, data } = await this.axios.get(url)

        if (status === 200) {
          this.merchants = data.data.branches
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
  },
}
</script>
